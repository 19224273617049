import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import HeaderSEO from '../../components/partials/HeaderSEO'

import Spacing from '../../components/partials/Spacing'

import Layout from '../../components/Layout'
import { StaticImage } from 'gatsby-plugin-image'

import Form from '../../components/forms/Form'
import { getCookieByName } from '../../helpers/getCookieByName'

const kafkaGuide = () => {
  const data = useStaticQuery(graphql`
    query Kafka_Guide_Form_SEO {
      allContentfulPages(
        filter: {
          pageReference: { pageIdentifier: { eq: "Kafka_Guide_Form_Page" } }
        }
      ) {
        edges {
          node {
            title {
              title
            }
            keywords {
              keywords
            }
            description {
              description
            }
            oGimage {
              fluid(quality: 100) {
                src
              }
            }
          }
        }
      }
      allContentfulAsset(
        filter: { id: { eq: "f17915f7-fd84-5f2f-ba2a-21c2cda296d0" } }
      ) {
        edges {
          node {
            id
            file {
              url
            }
          }
        }
      }
    }
  `)

  const pageSEO = data.allContentfulPages.edges[0].node

  const onClick = () => {
    document.querySelector<HTMLInputElement>('#splunk_id').value =
      getCookieByName('mintjs%3Auuid')
    document.querySelector<HTMLInputElement>('#formsubmit').disabled = true
  }

  return (
    <Layout>
      <HeaderSEO pageSEO={pageSEO} />

      <section className="mobile-margin-top overflow-hidden hero-margin">
        <div className="container-1 mobile-margin-top">
          <div className="d-flex flex-wrap">
            <div className="col-lg-7 offset-sm-1  col-sm-10 col-12 offset-lg-0">
              <p className="h4-like mobile-margin-reset">Guide</p>
              <h1 className="pr-5">
                All you need to know about Kafka monitoring & data observability
              </h1>

              <Spacing />
              <StaticImage
                src="../../resources/images/kafka_guide/hero-guide.svg"
                placeholder="tracedSVG"
                alt="Kafka Guide Monitoring"
              />
            </div>
            <div className="px-0 col-lg-4 offset-sm-1  col-sm-10 col-12 pt-5">
              <div className="footer-color py-5 px-2 rounded">
                <div className="d-flex flex-wrap">
                  <div className="col-md-12 mobile-padding-reset">
                    {/* ATTENTION! Form ID needs to be matching Zoominfo settings as described here https://landoop.atlassian.net/wiki/spaces/MAR/pages/2910453761/Zoominfo+Website+integration */}
                    <Form
                      formId="zoominfo-form-with-phone"
                      action="https://go.pardot.com/l/877242/2020-08-04/4fkwlm"
                      onSubmit={onClick}
                      inputId="formsubmit"
                      inputValue="Read Now"
                      firstName={'required'}
                      lastName={'required'}
                      email={'required'}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Spacing />
      </section>

      <section className="bg-cyan mobile-margin-top">
        <div className="container-1 py-5">
          <div className="d-flex flex-wrap justify-content-center">
            <div className="col-10">
              <h2 className="f-32 fw-900 text-center">
                Struggling to drive Kafka adoption from the command line across
                your organization?
              </h2>

              <p className="text-center">
                Kafka observability on its own is complicated with so many
                components - then there’s your data, your core services and your
                platform tenants to serve.
              </p>
            </div>
          </div>
        </div>
      </section>

      <Spacing />

      <div className="container-1">
        <div className="d-flex flex-wrap mb-5 pb-5 mobile-margin-reset justify-content-center mobile-margin-top">
          <div className="col-12">
            <h2 className="f-32 fw-900 text-center">
              What’s in the guide to Kafka observability?
            </h2>
          </div>
          <Spacing />
          <div className="col-sm-4 col-12">
            <ul className="red-checked-list">
              <li>
                <span>
                  Key principles for starting up and scaling your Kafka, with an
                  approach that grows with your data platform and teams
                </span>
              </li>
            </ul>
          </div>
          <div className="col-sm-4 col-12">
            <ul className="red-checked-list">
              <li>
                <span>
                  All the observability aspects you need to consider by
                  stakeholder, data type and infrastructure component
                </span>
              </li>
            </ul>
          </div>

          <div className="col-sm-4 col-12">
            <ul className="red-checked-list">
              <li>
                <span>
                  How to factor in observability beyond Kafka and throughout
                  your event-driven architecture, from alerts and audits to
                  metadata and metrics
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </Layout>
  )
}
export default kafkaGuide
